<template>
  <q-form ref="editForm">
    <c-card title="LBLBASEINFO" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn
            v-show="!isOld"
            label="타 사고 기본정보 불러오기"
            icon="save_alt"
            @btnClicked="copyAccident"
          />
          <c-update-btn 
            name="updateBtn"
            :data="param.regUpdateBtnData"
            :btnEditable="btnEditable"
            :flagCondition="flagCondition"
            @back="back"
          />
          <c-btn v-show="isOld && !updateDisabled" label="LBLREMOVE" :editable="editable" icon="delete_forever" @btnClicked="remove" />
          <!-- 완료 -->
          <c-btn 
            v-show="isOld && !updateDisabled" 
            :isSubmit="isComplete"
            :url="completeUrl"
            :param="accData"
            mappingType="PUT"
            label="LBLCOMPLETE" 
            icon="check"
            @beforeAction="completeAccident"
            @btnCallback="completeCallback" />
          <!-- 저장 -->
          <c-btn
            v-show="!disabled"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="accData"
            :mappingType="saveType"
            label="LBLSAVE"
            icon="save"
            @beforeAction="saveAccident"
            @btnCallback="saveCallback" 
          />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-text
            required
            :editable="editable"
            :disabled="disabled"
            label="재해명"
            name="accidentName"
            v-model="accData.accidentName">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 발생형태 -->
          <c-select
            required
            type="edit"
            :editable="editable"
            :disabled="disabled"
            codeGroupCd="IIM_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            name="accidentTypeCd"
            label="발생형태"
            v-model="accData.accidentTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            required
            type="edit"
            :editable="editable"
            :disabled="disabled"
            codeGroupCd="IIM_OCCUR_CD"
            itemText="codeName"
            itemValue="code"
            name="occurrenceCd"
            label="재해유형"
            v-model="accData.occurrenceCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 재해일시 -->
          <c-datepicker
            required
            :editable="editable"
            type="datetime"
            :disabled="disabled"
            :minuteStep="10"
            label="재해일시"
            name="occurrenceDate"
            v-model="accData.occurrenceDate"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 발생부서 -->
          <c-dept
            :required="true" 
            :editable="editable"
            :disabled="disabled"
            label="발생부서"
            name="occurrenceDeptCd"
            v-model="accData.occurrenceDeptCd">
          </c-dept>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 공정 -->
          <c-process
            :editable="editable"
            :disabled="disabled"
            label="LBLPROCESS"
            name="processCd"
            v-model="accData.processCd">
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 재해장소 -->
          <c-text
            :required="true" 
            :editable="editable"
            :disabled="disabled"
            label="재해장소"
            name="occurrenceLocation"
            v-model="accData.occurrenceLocation">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 단위작업명 -->
          <c-text
            :editable="editable"
            :disabled="disabled"
            label="단위작업명"
            name="jobName"
            v-model="accData.jobName">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 모드 -->
          <c-text
            :editable="editable"
            :disabled="disabled"
            label="모드"
            name="accidentMode"
            v-model="accData.accidentMode">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 라인 -->
          <c-text
            :editable="editable"
            :disabled="disabled"
            label="라인"
            name="accidentLine"
            v-model="accData.accidentLine">
          </c-text>
        </div>
          <!-- 사고번호(품번) -->
        <!-- <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            :editable="editable"
            :disabled="true"
            label="사고번호(품번)"
            name="accidentNo"
            v-model="accData.accidentNo">
          </c-text>
        </div> -->
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant 
            :required="true" 
            :disabled="disabled"
            :editable="editable" 
            type="edit" 
            name="plantCd" 
            v-model="accData.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <!-- 재해발생경위 -->
          <c-textarea
            :required="true" 
            :editable="editable"
            :disabled="disabled"
            :isCheck="true"
            :rows="6"
            label="재해발생경위"
            name="overviewAccidentSituation"
            v-model="accData.overviewAccidentSituation"
            @checkboxClick="checkboxClick"
            >
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-field
                :editable="editable"
                :disabled="disabled"
                :isFirstValue="false"
                :data="accData"
                label="가해자"
                type="dept_user"
                name="attackerUserId"
                v-model="accData.attackerUserId">
              </c-field>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-field
                :editable="editable"
                :disabled="disabled"
                :isFirstValue="false"
                :data="accData"
                label="목격자"
                type="dept_user"
                name="witnessUserId"
                v-model="accData.witnessUserId">
              </c-field>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-text
                :editable="editable"
                :disabled="disabled"
                label="기인물"
                name="openning"
                v-model="accData.openning">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-text
                :editable="editable"
                :disabled="disabled"
                label="가해물"
                name="injurious"
                v-model="accData.injurious">
              </c-text>
            </div>
          </div>
        </div>
      </template>
    </c-card>
    <c-card title="사고자 정보" class="cardClassDetailForm">
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-field
            required
            :editable="editable"
            :disabled="disabled"
            :isFirstValue="false"
            :data="accData"
            :targetDeptCd="accData.occurrenceDeptCd"
            label="사고자"
            type="dept_user"
            name="accidentUserId"
            v-model="accData.accidentUserId"
            @userInfo="accUserDataChange">
          </c-field>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            :editable="editable"
            :disabled="true"
            label="입사일자"
            name="accidentEnterDate"
            v-model="accData.accidentEnterDate">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            :editable="editable"
            :disabled="true"
            label="생년월일"
            name="accidentBirthDate"
            v-model="accData.accidentBirthDate">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            :editable="editable"
            :disabled="true"
            label="직위"
            name="accidentSpotName"
            v-model="accData.accidentSpotName">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            :editable="editable"
            :disabled="true"
            label="연령/성별/근속년수"
            name="companyInfo"
            v-model="companyInfo">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            :disabled="true"
            label="정규/임시직"
            name="regulFlagName"
            v-model="accData.regulFlagName">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            required
            type="edit"
            :editable="editable"
            :disabled="disabled"
            codeGroupCd="IIM_WOUND_CD"
            itemText="codeName"
            itemValue="code"
            name="woundCd"
            label="상해종류"
            v-model="accData.woundCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            required
            type="edit"
            :editable="editable"
            :disabled="disabled"
            codeGroupCd="IIM_WOUND_FST_CD"
            itemText="codeName"
            itemValue="code"
            name="woundFstCd"
            label="상해부위(대)"
            v-model="accData.woundFstCd"
            @datachange="secDataChange"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            required
            type="edit"
            :editable="editable"
            :disabled="disabled"
            :comboItems="secItems"
            itemText="codeName"
            itemValue="code"
            name="woundSecCd"
            label="상해부위(중)"
            v-model="accData.woundSecCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            type="edit"
            :editable="editable"
            :disabled="disabled"
            codeGroupCd="IIM_FRUIT_CD"
            itemText="codeName"
            itemValue="code"
            name="fruitCd"
            label="과실여부"
            v-model="accData.fruitCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            type="edit"
            :editable="editable"
            :disabled="disabled"
            codeGroupCd="IIM_WARNING_CD"
            itemText="codeName"
            itemValue="code"
            name="warningCd"
            label="경고장"
            v-model="accData.warningCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 요양기간(시작일 ~ 복귀일) -->
          <c-datepicker
            :editable="editable"
            :range="true"
            :disabled="disabled"
            :minuteStep="10"
            label="요양기간(시작일 ~ 복귀일)"
            name="accidentPeriod"
            v-model="accidentPeriod"
            @datachange="periodChange"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            :editable="editable"
            :disabled="true"
            type="number"
            suffix="일"
            label="휴업일수(주말 제외)"
            name="nursingCount"
            v-model="accData.nursingCount">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            :editable="editable"
            :disabled="disabled"
            label="병원"
            name="hospitalName"
            v-model="accData.hospitalName">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            :editable="editable"
            :disabled="disabled"
            suffix="원"
            type="number"
            label="병원진료비(요양비)"
            name="hospitalCost"
            v-model="accData.hospitalCost">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            :editable="editable"
            :disabled="disabled"
            label="진단명"
            name="diagnosisName"
            v-model="accData.diagnosisName">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            :editable="editable"
            :disabled="disabled"
            type="number"
            suffix="원"
            label="기타 장해보상금"
            name="wage"
            v-model="accData.wage">
          </c-text>
        </div>
      </template>
    </c-card>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'process-register-info',
  props: {
    param: {
      type: Object,
      default: () => ({
        iimAccidentId: '',
        stepCd: '',
        regUpdateBtnData: {
          title: '사고등록',
          flag: false,
          research: '',
          planResearch: '',
          recResearch: '',
        },
      }),
    },
  },
  data() {
    return {
      accData: {
        iimAccidentId: '',  // 공정사고 일련번호
        accidentTypeCd: null,  // 재해유형
        plantCd: '',  // 사업장 코드
        accidentNo: '',  // 사고번호
        accidentName: '',  // 사고명
        accidentStatusCd: null,  // 사고등록/접수/조사중/종결
        occurrenceDate: '',  // 발생일시
        occurrenceDeptCd: null,  // 발생부서
        occurrenceLocation: '',  // 발생장소
        accidentCause: '',  // 재해발생원인
        overviewAccidentSituation: '',  // 재해발생경위
        occurrenceCd: null,  // 발생형태코드
        openning: '',  // 기인물
        injurious: '',  // 가해물
        managerOpinion: '',  // 환경안전팀 의견
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        woundCd: null,  // 상해종류
        woundFstCd: null, // 상해부위(대)
        woundSecCd: null,  // 상해부위(중)
        fruitCd: null,  // 과실여부
        warningCd: null,  // 경고장
        engineeringRecurrence: '',  // 재발방지대책(공학적)
        managementRecurrence: '',  // 재발방지대책(관리적)
        accidentUserId: '',  // 사고자
        accidentEnterDate: '',
        accidentBirthDate: '',  
        accidentMobileNo: '',  
        accidentEmpNo: '',  
        accidentSpotName: '',  
        accidentStartDate: '',  // 사고시작일
        accidentEndDate: '',  
        accidentAge: '', 
        accidentSexName: '',  
        accidentLongevityCount: '', 
        attackerUserId: '',  // 가해자
        witnessUserId: '',  // 목격자
        hospitalName: '',  // 병원
        hospitalCost: '',  // 병원진료비
        nursingCount: 0,
        wage: '',  // 임금
        diagnosisName: '',  // 진단명
        jobName: '',  // 단위작업명
        accidentMode: '',  // 모드
        accidentLine: '',  // 라인
        processCd: '',  // 라인
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        regulFlagName: '',
      },
      editable: true,
      saveUrl: '',
      completeUrl: '',
      saveType: 'POST',
      isSave: false,
      isComplete: false,
      getUrl: '',
      insertUrl: '',
      deleteUrl: '',
      updateUrl: '',
      accidentPeriod: [],
      secItems: [],
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        isFull: false,
        top: "",
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    isOld() {
      return Boolean(this.accData.iimAccidentId)
    },
    updateDisabled() {
      return Boolean(this.accData.accidentStatusCd) && this.accData.accidentStatusCd !== 'ISPC000001'
    },
    disabled() {
      return Boolean(this.accData.accidentStatusCd) && this.accData.accidentStatusCd !== 'ISPC000001' && !this.param.regUpdateBtnData.flag
    },
    isEnvSafDept() { 
      return this.$_.indexOf(this.$store.getters.auths, 'SAGI000025') > -1 || this.$store.getters.user.empNo === 'admin'
    },
    btnEditable() {
      return this.editable && ((this.accData.accidentStatusCd !== 'ISPC000005' && this.accData.accidentStatusCd !== 'ISPC000001') && Boolean(this.accData.iimAccidentId) 
      || (this.accData.accidentStatusCd === 'ISPC000005' && this.isEnvSafDept))
    },
    flagCondition() {
      return this.accData.accidentStatusCd === 'ISPC000001' 
    },
    companyInfo() {
      return this.accData.accidentAge + '/' + this.accData.accidentSexName + '/' + this.accData.accidentLongevityCount;
    },
  },
  watch: {
    'param.regUpdateBtnData.planResearch'() {
      this.getDetail();
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.getUrl = selectConfig.sop.iim.accident.process.get.url;
      this.insertUrl = transactionConfig.sop.iim.accident.process.insert.url;
      this.updateUrl = transactionConfig.sop.iim.accident.process.update.url;
      this.saveUrl = transactionConfig.sop.iim.accident.process.insert.url;
      this.deleteUrl = transactionConfig.sop.iim.accident.process.delete.url;
      this.completeUrl = transactionConfig.sop.iim.accident.process.complete.url;
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.param.iimAccidentId) {
        this.$http.url = this.$format(this.getUrl, this.param.iimAccidentId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$emit('setRegInfo', _result.data)
          this.accData = _result.data;
          if (this.accData.accidentStartDate && this.accData.accidentEndDate) {
            this.accidentPeriod = [this.accData.accidentStartDate, this.accData.accidentEndDate]
          }
          this.secDataChange();
        },);
      } else {
        this.accData.reportUserId = this.$store.getters.user.userId
      }
    },
    saveAccident() {
      if (this.param.iimAccidentId) {
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.saveType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.accData.regUserId = this.$store.getters.user.userId
              this.accData.chgUserId = this.$store.getters.user.userId
              
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.param, 'iimAccidentId', result.data)
      this.$emit('emitStep', {
        name: 'keySetting',
        param: this.param.iimAccidentId
      })
      this.getDetail();
      this.param.regUpdateBtnData.recResearch = uid();
    },
    checkboxClick(checkValue) {
      if (checkValue === 'O') {
        this.accData.overviewAccidentSituation = 
          '- ' +
          '누가 : \n' + // 누가
          '- ' +
          '누구에게 : \n' + // 누구에게
          '- ' +
          '언제 : \n' + // 언제
          '- ' +
          '어디서 : \n' + // 어디서
          '- ' +
          '무엇을 : \n' + // 무엇을
          '- ' +
          '어떻게 : \n' + // 어떻게
          '- ' +
          '왜 : ';
      } else {
        this.accData.overviewAccidentSituation = '';
      }
    },
    completeAccident() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: '사고등록 하시겠습니까?', // 사고등록 하시겠습니까?
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.accData.accidentStatusCd = 'ISPC000003'
          this.accData.regUserId = this.$store.getters.user.userId
          this.accData.chgUserId = this.$store.getters.user.userId
          
          this.isComplete = !this.isComplete;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    completeCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.accData.accidentStatusCd) {
        this.$emit('emitStep', {
          name: 'currentStep',
          param: this.accData.accidentStatusCd 
        })
      }
    },
    /* eslint-disable no-unused-vars */ 
    remove() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.accData.iimAccidentId);
          this.$http.type = 'DELETE';
          this.$http.request((_result) => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            
            this.$emit('emitStep', {
              name: 'closePopup',
              param: {},
            })
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    /**
     * 재조회
     * 목적 : 돌아가기전 데이터를 저장 했을 수도 있지만 
     *        입력만 하고 돌아가는 경우를 대비하기 위함
     */
    back() {
      this.getDetail();
    },
    accUserDataChange(accUserInfo) {
      if (accUserInfo) {
        this.$set(this.accData, 'accidentSpotName', accUserInfo.spotName);
        this.$set(this.accData, 'accidentEmpNo', accUserInfo.empNo);
        this.$set(this.accData, 'accidentMobileNo', accUserInfo.mobileNo);
        this.$set(this.accData, 'accidentBirthDate', accUserInfo.birthDate);
        this.$set(this.accData, 'accidentEnterDate', accUserInfo.enterDate);
        this.$set(this.accData, 'accidentLongevityCount', accUserInfo.longevityCount);
        this.$set(this.accData, 'accidentSexName', accUserInfo.sexName);
        this.$set(this.accData, 'accidentAge', accUserInfo.age);
        this.$set(this.accData, 'regulFlagName', accUserInfo.regulFlagName);
        
      } else {
        this.$set(this.accData, 'accidentSpotName', '');
        this.$set(this.accData, 'accidentEmpNo', '');
        this.$set(this.accData, 'accidentMobileNo', '');
        this.$set(this.accData, 'accidentBirthDate', '');
        this.$set(this.accData, 'accidentEnterDate', '');
        this.$set(this.accData, 'accidentLongevityCount', '');
        this.$set(this.accData, 'accidentSexName', '');
        this.$set(this.accData, 'accidentAge', '');
        this.$set(this.accData, 'regulFlagName', '');
      }
    },
    periodChange() {
      if (this.accidentPeriod && this.accidentPeriod.length > 0) {
        this.accData.accidentStartDate = this.accidentPeriod[0]
        this.accData.accidentEndDate = this.accidentPeriod[1]
        this.accData.nursingCount = this.$comm.getBusinessDaysDiff(this.accData.accidentStartDate, this.accData.accidentEndDate)
      } else {
        this.accData.accidentStartDate = '';
        this.accData.accidentEndDate = '';
        this.accData.nursingCount = 0;
      }
    },
    secDataChange() {
      this.$comm.getComboItems('IIM_WOUND_SEC_CD', this.accData.woundFstCd).then(_result => {
        this.secItems = _result;
      })
    }, 
    copyAccident() {
      this.popupOptions.title = "타 사고 검색"; // 타 사고 검색
      this.popupOptions.param = {
        type: "single",
      };
      this.popupOptions.target = () => import(`${"../accidentPop.vue"}`);
      this.popupOptions.width = "70%";
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeCopyAccidentPopup;
    },
    closeCopyAccidentPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        if (data[0].iimAccidentId) {
          this.$http.url = this.$format(this.getUrl, data[0].iimAccidentId);
          this.$http.type = "GET";
          this.$http.request((_result) => {
            this.$set(this.accData, 'accidentName', _result.data.accidentName)
            this.$set(this.accData, 'accidentTypeCd', _result.data.accidentTypeCd)
            this.$set(this.accData, 'occurrenceDate', _result.data.occurrenceDate)
            this.$set(this.accData, 'occurrenceDeptCd', _result.data.occurrenceDeptCd)
            this.$set(this.accData, 'processCd', _result.data.processCd)
            this.$set(this.accData, 'occurrenceLocation', _result.data.occurrenceLocation)
            this.$set(this.accData, 'accidentMode', _result.data.accidentMode)
            this.$set(this.accData, 'accidentLine', _result.data.accidentLine)
            this.$set(this.accData, 'plantCd', _result.data.plantCd)
            this.$set(this.accData, 'overviewAccidentSituation', _result.data.overviewAccidentSituation)
            this.$set(this.accData, 'attackerUserId', _result.data.attackerUserId)
            this.$set(this.accData, 'witnessUserId', _result.data.witnessUserId)
            this.$set(this.accData, 'openning', _result.data.openning)
            this.$set(this.accData, 'injurious', _result.data.injurious)

          });
        }
      }
    }
  }
};
</script>
